export default function HowToUseInterviewQuestionGenerator() {
    return (
        <section
            style={{
                backgroundColor: "white",
                padding: "60px 20px",
                textAlign: "center",
            }}
        >
            {/* Heading */}
            <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                <h2
                    style={{
                        fontSize: "32px",
                        fontWeight: "bold",
                        color: "#4F46E5",
                    }}
                >
                    How to Use Our Interview Question Generator?
                </h2>
            </div>

            {/* Steps Container */}
            <div
                style={{
                    maxWidth: "900px",
                    margin: "40px auto",
                    textAlign: "left",
                }}
            >
                {[
                    {
                        title: "Select a Job Title",
                        desc: "Choose from 200+ job titles or type in a custom title for a tailored interview experience.",
                    },
                    {
                        title: "Choose the Seniority Level (Optional)",
                        desc: "Select from Entry-Level, Mid-Level, or Senior-Level to ensure questions match experience.",
                    },
                    {
                        title: "Select Question Type (Optional)",
                        desc: "Pick question types like Technical, Behavioral, Situational, and more.",
                    },
                    {
                        title: "Choose a Company (Optional)",
                        desc: "Search from thousands of companies or add a custom one for specific interview questions.",
                    },
                    {
                        title: "Generate AI-Powered Interview Questions",
                        desc: "Click 'Generate' and receive 10 high-quality interview questions instantly.",
                    },
                ].map((step, index) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "start",
                            marginBottom: "40px",
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "50px",
                                height: "50px",
                                flexShrink: 0,
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#4F46E5",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    borderRadius: "50%",
                                    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                }}
                            >
                                {index + 1}
                            </div>
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h3
                                style={{ fontSize: "22px", fontWeight: "bold" }}
                            >
                                {step.title}
                            </h3>
                            <p style={{ color: "#666" }}>{step.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
